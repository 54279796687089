.app-background { 
    background: url(/public/background.png) no-repeat center center fixed; 
    background-color: #000; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.app-background2 { 
    background: url(/public/background2.png) no-repeat center center fixed; 
    background-color: #000; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.app {
    text-align: center;
}
.app-logo {
    height: 50px;
    cursor: pointer !important;
    opacity: 0.6;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    padding-bottom: 50px;
}
.app-logo:hover {
    opacity: 1;
}
.app-presale{
    display: inline-block;
    font-family: "Roboto";
    font-size: 30px;
    color: #fff;
    font-weight: 100;
}
.parallax-effect {
    transform-style: preserve-3d;
}
.app-mint {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 30px;
    min-height: 280px;
    min-width: 280px;
    border: 1px solid #fff;
    font-weight: 800;
    transform: translateZ(80px);
}
.app-left-button{
    padding-right: 40px;
    display: inline-block;
}
.app-right-button{
    padding-left: 40px;
    display: inline-block;
}
.app-mint-amount{
    display: inline-block;
    font-family: "Roboto";
    font-size: 30px;
    color: #fff;
    font-weight: 100;
}
.app-quantity-button{
    text-align: center;
    background-color: rgba(255, 255, 255, 0.700);
    border-radius: 10px;
    height: 35px;
    width: 35px;
    border: 1px solid #fff;    
    /* font-size: 25px; */
    color: #fff;
    opacity: 0.6;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}
.app-quantity-button:hover {
    opacity: 1;
    cursor: pointer;
}
.app-icon{
    fill: #fff;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}
.app-icon:hover{
    fill: #000;
}
.app-mint-button{
    text-align: center;
    background-color: rgba(255, 255, 255, 0.700);
    border-radius: 10px;
    height: 35px;
    border: 1px solid #fff;    
    font-size: 20px;
    font-weight: 100;
    color: #fff;
    opacity: 0.6;
    font-family: "Roboto";
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}
.app-mint-button:hover {
    opacity: 1;
    cursor: pointer;
    color: #000;
}
.app-spacing {
    padding-bottom: 100px;
    padding-top: 55px;
}
.app-social {
    opacity: 0.6;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    height: 30px;
    cursor: pointer !important;
    padding-left: 10px;
    padding-right: 10px;
}
  .app-social:hover {
    opacity: 1;
}
.app-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
h1 {
    font-family: "Roboto";
    font-size: 30px;
    color: #fff;
    font-weight: 100;
    text-transform: uppercase;
    opacity: 1;
    letter-spacing: 10px;
}
p {
    font-family: "Roboto";
    font-size: 15px;
    color: #fff;
    font-weight: 100;
    text-transform: uppercase;
    opacity: 1;
    letter-spacing: 1px;
}
a{
    color: #fff;
}
#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}
